import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import moment from 'moment';
import Sidebar from "./components/Sidebar"
import PatientBox from "./components/PatientBox"
import PatientBoxLoading from "./components/PatientBoxLoading"
import { Link } from "react-router-dom";

import NavButton from "components/NavButton";

import { Select } from "components/Forms"

import getCurrentDate from "utils/getCurrentDate"
import createRegistrationLink from "utils/createRegistrationLink";
import isStaging from "utils/isStaging";

import {
  ButtonContainer,
  Flex,
  TextBlue
} from "./main.styles";

function mapStateToProps(state) {
  return {
    isValidated: state.user.buvidal.isValidated,
    patients: state.user.patients,
    userId: state.user.id,
    user: state.user,
    orders: state.user.orders ? state.user.orders : []
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const filter = [
  { value: {sortItem: 'initials', sortOrder: 1}, label: 'Initials (A - Z)' },
  { value: {sortItem: 'initials', sortOrder: -1}, label: 'Initials (Z - A)' },
  { value: {sortItem: 'dob', sortOrder: 1}, label: 'DOB (Ascending)' },
  { value: {sortItem: 'dob', sortOrder: -1}, label: 'DOB (Descending)' },
];

class Patients extends Component {

  constructor() {
    super();
    this.state = {
      sortItem: 'initials', 
      sortOrder: 1
    }
  }

  changeSortOrder(value) {
    this.setState(state=>({...state, ...value}));
  }

  render() {
    const enrolmentOpen = true

    let patients = this.props.patients.map(patient=>{
      if (patient.state === 'active' && patient.shipmentDate) {
        let shipmentDate = moment.utc(patient.shipmentDate);
        let currentDate = getCurrentDate();
        currentDate.add(7, 'days');
        let state = patient.state;
        let isConfirmed = false;
        if (patient.orderConfirmedDate && moment.utc(patient.orderConfirmedDate).isSame(shipmentDate, 'day')) {
          isConfirmed = true;
        }
        if (currentDate.isAfter(shipmentDate) && !isConfirmed) {
          state = 'confirm';
        }
        let is8mgConfirmed = false;
        if (patient.additional8mgOrderConfirmedDate && moment.utc(patient.additional8mgOrderConfirmedDate).isSame(moment.utc(patient.additional8mgShipmentDate), 'day')) {
          is8mgConfirmed = true;
        }
        if (this.props.userId !== patient.userId) {
          state = 'unmanaged';
        }
        return {...patient, state, isConfirmed, is8mgConfirmed};
      } else {
        return {...patient};
      }
    });

    if (this.state.sortItem === 'initials') {
      patients.sort((a,b)=>{
        if (a.initials > b.initials) {
          return 1 * this.state.sortOrder;
        } else if (a.initials < b.initials) {
          return -1 * this.state.sortOrder;
        } else {
          return 0;
        }
      });
    } else if (this.state.sortItem === 'dob') {
      patients.sort((_a,_b)=>{
        let a = moment(_a.dob);
        let b = moment(_b.dob);
        if (a.isAfter(b)) {
          return 1 * this.state.sortOrder;
        } else if (a.isBefore(b)) {
          return -1 * this.state.sortOrder;
        } else {
          return 0;
        }
      });
    }


    let enrolledPatients = this.props.patients.filter(patient=>this.props.userId === patient.prescriberId);
    let activePatients = this.props.patients

    const ordersAmount = (this.props.orders.length / 2)
    const maxPatients = this.props.user.buvidal.maxPatients || 10
    let remainingOrders = Math.max(0, Math.min(maxPatients, (activePatients.length - ordersAmount) + 1))
    if(ordersAmount >= this.props.user.buvidal.maxPatients) {
      remainingOrders = 0
    }
    const canOrder = !!(remainingOrders !== 0)

    const queryString = createRegistrationLink(this.props.user)

    if(!enrolmentOpen || !this.props.isValidated) { // remove this.props.isValidated its only for showing the client on staging
      return (
        <main className={"dashboard content-page " + this.props.type}>
        <Sidebar />
        <div className="content">
          <h1>Patient Dashboard</h1>
          <h2>Access pending validation of your registration.</h2>
          <p><strong>Note: validation to participate in the program is not authorisation to prescribe Buvidal<sup>®</sup>. Prescribers are responsible for obtaining authorisation to prescribe long-acting buprenorphine as/where required by State regulations.</strong></p>
          <p>If a drug order account needs to be set-up or confirmed, contact the PFP Administrator for assistance.</p>
          <p>You will receive a confirmation that your registration is complete, and confirmation when your drug order has been processed once your registration has been validated.</p>
          <p>Should you have any questions regarding your registration status, drug orders, or require assistance, please contact the PFP Administrator on 1300 862 994.</p>
          <p>In the meantime, you can access up to date information and resources at <a target='_blank' rel="noopener noreferrer" href={`https://${isStaging() ? 'staging.' : ''}buvidal.com.au`}>buvidal.com.au</a></p>
          <p>If you are not currently registered to access the Buvidal<sup>®</sup> site for healthcare professionals, <a target='_blank' rel="noopener noreferrer" href={`https://${isStaging() ? 'staging.' : ''}buvidal.com.au/account/sign-up/?${queryString}`}>Click Here</a> to complete registration.</p>
          <p>The HCP Guide, TGA-approved educational resources for healthcare professionals and patients, Product Information and Consumer Medicines Information for Buvidal<sup>®</sup> products, Patient Alert Card, and patient consent form can be accessed at any time through the <Link to='/buvidal/resources'>Resources tab</Link>.</p>
          <p>The Quality Use of Medicines Summary can be accessed through the <Link to='/buvidal/resources'>Resources tab</Link> as well. The <Link to='/buvidal/terms-and-conditions'>PFP Terms and Conditions</Link> can be accessed through the menu to the left.</p>
        </div>
      </main>
      )
    }

    if (!this.props.isValidated) {
      return (
        <main className={"dashboard content-page " + this.props.type}>
          <Sidebar />
          <div className="content">
            <h1>Patient Dashboard</h1>
            <h2>Access pending validation of your registration.</h2>
            <p>Your registration has been submitted for validation.</p>
            <p><strong>Validation to participate in the program is not authorisation to prescribe Buvidal<sup>®</sup>. Prescribers are responsible for obtaining authorisation to prescribe the long-acting buprenorphine formulation as/where required by State regulations.</strong></p>
            <p>If a drug order account needs to be set-up or confirmed, and your drug order will not be processed until. Validation cannot be finalised until this step is complete.</p>
            <p>You will receive a confirmation that your drug order has been processed when your registration has been validated.</p>
            <p>Should you have any questions regarding your registration status, drug orders, or require assistance, please contact the PFP Administrator on 1300 862 994.</p>
            <p>In the meantime, you can access up to date information and resources at <a target='_blank' rel="noopener noreferrer" href={`https://${isStaging() ? 'staging.' : ''}buvidal.com.au`}>buvidal.com.au</a></p>
            <p>If you are not currently registered to access the Buvidal<sup>®</sup> site for healthcare professionals, <a target='_blank' rel="noopener noreferrer" href={`https://${isStaging() ? 'staging.' : ''}buvidal.com.au/account/sign-up/?${queryString}`}>Click Here</a> to complete registration.</p>
            <p>The program guide, TGA-approved educational resources for healthcare professionals and patients, Product Information and Consumer Medicines Information for Buvidal<sup>®</sup> products, patient alert card, and patient consent form can be accessed at any time through the <Link to='/buvidal/resources'>Resources tab</Link>.</p>
            <p>The Quality Use of Medicines Summary can be accessed through the <Link to='/buvidal/resources'>Resources tab</Link> as well. The <Link to='/buvidal/terms-and-conditions'>PFP Terms and Conditions</Link> can be accessed through the menu to the left.</p>
          </div>
        </main>
      )
    }

    switch ("2") {
      case "1":
        return (
          <main className={"dashboard content-page " + this.props.type}>
            <Sidebar />
            <div className="content">
              <h1>Patient Dashboard</h1>
              <h2>Patient enrolment has not opened for this program</h2>
              <p>You are fully registered to participate in the Buvidal Weekly/Monthly PFP. However, Patient Enrolment has not yet opened.</p>
              <p>You will receive a notification from the PFP Administrator once patient enrolment is open, with a link to return to the site to start the patient enrolment process.</p>
            </div>
          </main>
        )
      case "2":
        return (
          <main className={"dashboard content-page " + this.props.type}>
            <Sidebar />
              <div className="content">
                <Flex>
                  <h1>Patient Dashboard</h1>
                  {activePatients.length < maxPatients &&
                    <ButtonContainer>
                      <div className={canOrder ? '' : 'rollover'} data-tooltip={canOrder ? null : "You do not have an initiation pack available. Once a patient is enrolled, you will be able to order another Initiation Pack"}>
                        <NavButton to="/buvidal/patients/onboarding-drug-order" className={`button-type-1 ${canOrder ? '' : 'button-type-disabled'}`} disabled={!canOrder}>Order Initiation Pack</NavButton>
                      </div>
                        <NavButton to="/buvidal/patients/onboarding-enrol-patient" className={`button-type-1 right`}>Enrol new patient</NavButton>
                    </ButtonContainer>
                  }
                </Flex>

                <div>
                    <div className="search-details">
                      <div>
                        <p style={{width: '100%'}}><strong>Patients enrolled:</strong> <TextBlue>{activePatients.length}</TextBlue> ({Math.max(0, maxPatients - activePatients.length)} remain)</p>
                        <p style={{width: '100%'}}><strong>Induction packs ordered:</strong> <TextBlue>{ordersAmount}</TextBlue></p>
                      </div>
                      {this.props.patients.length > 0 &&
                        <form>
                          <Select defaultValue={filter[0]} onChange={value=>this.changeSortOrder(value)} styling="float filter l" name="user.patient.filter" options={filter} isSearchable={false} />
                        </form>
                      }                      
                    </div>
                    <hr />
                    {this.props.patients.length > 0 ? (
                      patients.map(patient=><PatientBox patient={patient} userId={this.props.userId} key={patient.id} />)
                    ) : (
                      <div className="no-patient-enrolled">
                        <h2>You have no patients enrolled.</h2>
                        <p>Click on <strong>Enrol new patient</strong> to get started.</p>
                      </div>
                    )
                    }
                    <br />
                    <p>The program guide, TGA-approved educational resources for healthcare professionals and patients, Product Information and Consumer Medicines Information for Buvidal<sup>®</sup> products, patient alert card, and patient consent form can be accessed at any time through the <Link to='/buvidal/resources'>Resources tab</Link>.</p>
                    <p>REMINDER: a maximum of one (1) Initiation Pack will be on hand at a time. A patient must be enrolled to allocate the pack before a new order can be submitted.</p>
                    <p>A maximum of one order per day will be processed.</p>
                  </div>
            </div>
          </main>
        )
      default:
    }
  }
}

Patients = reduxForm({
  form: 'Patients' // a unique name for this form
})(Patients);

export default connect(mapStateToProps, mapDispatchToProps)(Patients);

